import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Route,
  Routes,
} from 'react-router-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';
import InvestPage from './sections/Invest';

import 'bootstrap/dist/css/bootstrap.min.css';



function MyApp() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index path='/' element={<App />} />
        <Route path='/invest' element={<InvestPage />} />
      </Routes>
    </BrowserRouter>
  )
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <MyApp />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
